exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-expertise-energy-and-renewables-tsx": () => import("./../../../src/pages/expertise/energy-and-renewables.tsx" /* webpackChunkName: "component---src-pages-expertise-energy-and-renewables-tsx" */),
  "component---src-pages-expertise-environmental-protection-tsx": () => import("./../../../src/pages/expertise/environmental-protection.tsx" /* webpackChunkName: "component---src-pages-expertise-environmental-protection-tsx" */),
  "component---src-pages-expertise-financial-services-tsx": () => import("./../../../src/pages/expertise/financial-services.tsx" /* webpackChunkName: "component---src-pages-expertise-financial-services-tsx" */),
  "component---src-pages-expertise-food-tsx": () => import("./../../../src/pages/expertise/food.tsx" /* webpackChunkName: "component---src-pages-expertise-food-tsx" */),
  "component---src-pages-expertise-healthcare-tsx": () => import("./../../../src/pages/expertise/healthcare.tsx" /* webpackChunkName: "component---src-pages-expertise-healthcare-tsx" */),
  "component---src-pages-expertise-oil-and-gas-tsx": () => import("./../../../src/pages/expertise/oil-and-gas.tsx" /* webpackChunkName: "component---src-pages-expertise-oil-and-gas-tsx" */),
  "component---src-pages-expertise-tsx": () => import("./../../../src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */)
}

